<template>
  <div id="performance" class="wi100 hi100"></div>
</template>

<script>
const echarts = require('echarts')
export default {
  name: 'performance',
  props: {
    enterpriseId: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      color1: ['#FF3333', '#FF7744', '#FFCC22', '#33FF33', '#33CCFF', '#7744FF', '#E93EFF'],
      color2: ['#FF8888', '#FFA488', '#FFDD55', '#66FF66', '#77DDFF', '#9F88FF', '#E38EFF'],
      color3: ['#FFCCCC', '#FFC8B4', '#FFEE99', '#99FF99', '#CCEEFF', '#CCBBFF', '#F0BBFF'],
      performance: {},
      option: {
        title: {
          text: '企业经营状况',
          textStyle: {
            color: '#FFFFFF',
            fontSize: 18,
            fontWeight: 'bold'
          },
          left: 'center'
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLine: {
            show: true,
            lineStyle: {
              color: '#FFF'
            }
          },
          axisLabel: {
            show: true,
            interval: 0,
            margin: 15,
            rotate: 30,
            color: '#FFF',
          },
          axisTick: {
            show: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.2)',
              width: 2,
            },
          }
        },
        tooltip: {
          show: true,
          orient: 'vertical',
          formatter: '{b0}: {c0}',
        },
        series: [
          {
            type: 'bar',
            symbolPosition: 'start',
            barWidth: this.barWidth,
            label: {
              show: true,
              position: 'insideBottom',
              align: 'center',
              verticalAlign: 'middle',
              rotate: 0,
              formatter: '{c}'
            },
            z: 1,
            data: [],
          }
        ]
      },
      barWidth: 30,
      keys: {
        admCount: '行政许可数量',
        bondCount: '债权信息数量',
        cieCount: '进出口信用数量',
        lpuCount: '地块公示数量',
        lprCount: '购地信息数量',
        ltrCount: '土地转让数量',
        rectCount: '招聘数量',
        tendCount: '招投标数量',
        taxCount: '税务信用数量',
        teleCount: '电信许可数量',
        supCount: '供应商数量',
        cumCount: '客户数量',
        creCount: '信用评级数量',
        lpmCount: '国有土地受让数量'
      },
      xAxisData: [],
      yAxisData: []
    }
  },
  created() {
  },
  watch: {
    'performance': {
      handler: function (data) {
        // x轴数据
        let xAxisData = []
        // Y轴数据
        let yAxisData = []
        let keys = this.keys
        if (data) {
          let tempData = []
          for (let key in data) {
            if (keys[key] && data[key] > 0) {
              tempData.push({key: data[key]})
            }
          }
          for (let key in tempData) {
            keys[key] && xAxisData.push(keys[key])
            yAxisData.push(tempData[key])
          }
          this.xAxisData = xAxisData || []
          this.yAxisData = yAxisData || []
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.initPerformance()
    // this.draw()
  },
  methods: {
    initPerformance() {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.PERFORMANCE.INFO),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let performance = data['result'] || {}
          if (performance && JSON.stringify(performance) !== '{}') {
            let xAxisData = []
            let yAxisData = []
            let tempList = []
            // echarts数据处理（只展示数量不为0的数据）
            for (let key in performance) {
              if (this.keys[key] && performance[key] > 0) {
                tempList.push({key: key, value: performance[key]})
              }
            }
            // 排序
            tempList.sort((a, b) => {
              return b.value - a.value
            })
            let keys = this.keys
            tempList.map(function (item) {
              xAxisData.push(keys[item.key])
              yAxisData.push(item.value)
            })
            this.xAxisData = [...xAxisData]
            this.yAxisData = [...yAxisData]
            if (this.xAxisData && this.yAxisData) {
              this.draw()
            }
          } else {
            this.defaultDraw()
          }
        }
      })
    },
    draw() {
      let chartDom = document.getElementById('performance')
      let chartInstance = echarts.init(chartDom)
      let arr = []
      this.yAxisData.map((item, index) => {
        let obj = {
          value: item,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
              offset: 0,
              color: this.color1[index], // 0% 处的颜色
            },
              {
                offset: 0.6,
                color: this.color2[index], // 60% 处的颜色
              },
              {
                offset: 1,
                color: this.color3[index], // 100% 处的颜色
              }], false)
          }
        }
        arr.push(Object.assign({}, obj))
      })
      this.option.series[0].data = arr
      this.option.xAxis.data = [...this.xAxisData]
      chartInstance.setOption(this.option)
    },
    defaultDraw () {
      let chartDom = document.getElementById('performance')
      let myChart = echarts.init(chartDom)
      let option = {
        graphic: {
          elements: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              color: '#f0f0f0',
              style: {
                text: '企业经营状况(数据待完善)',
                fontSize: 40,
                fontWeight: 'bold',
                lineDash: [0, 100],
                lineDashOffset: 0,
                fill: 'transparent',
                stroke: '#409eff',
                lineWidth: 1
              },
              keyframeAnimation: {
                duration: 3000,
                loop: true,
                keyframes: [
                  {
                    percent: 0.7,
                    style: {
                      fill: 'transparent',
                      lineDashOffset: 200,
                      lineDash: [200, 0]
                    }
                  },
                  {
                    // Stop for a while.
                    percent: 0.8,
                    style: {
                      fill: 'transparent'
                    }
                  },
                  {
                    percent: 1,
                    style: {
                      fill: 'black'
                    }
                  }
                ]
              }
            }
          ]
        }
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>

</style>

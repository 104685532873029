<template>
  <div id="knowledge">
    <div class="fwb f18 wi100 tac" style="color: #27ae60">知识产权情况</div>
    <div class="knowledge-item" id="knowledge1"></div>
    <div class="knowledge-item" id="knowledge2"></div>
    <div class="knowledge-item" id="knowledge3"></div>
    <div class="knowledge-item" id="knowledge4"></div>
  </div>
</template>

<script>
export default {
  name: 'knowledge',
  props: {
    enterpriseId: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      option1: {
        dataSet:{
          source: []
        },
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '75%'],
            radius: '90%',
            min: 0,
            max: 100,
            splitNumber: 5,
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.25, '#FF6E76'],
                  [0.5, '#FDDD60'],
                  [0.75, '#58D9F9'],
                  [1, '#7CFFB2']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '12%',
              width: 10,
              offsetCenter: [0, '-40%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 12,
              lineStyle: {
                color: 'auto',
                width: 2
              }
            },
            splitLine: {
              length: 10,
              lineStyle: {
                color: 'auto',
                width: 5
              }
            },
            axisLabel: {
              color: '#FFFFFF',
              fontSize: 14,
              distance: -60,
              rotate: '90',
              formatter: function (value) {
                if (value === 100) {
                  return '优秀'
                } else if (value === 50) {
                  return '一般'
                // } else if (value === 40) {
                //   return '一般'
                } else if (value === 0) {
                  return '较差'
                }
                return ''
              }
            },
            title: {
              offsetCenter: [0, 0],
              fontSize: 20
            },
            detail: {
              fontSize: 18,
              offsetCenter: [0, '-22%'],
              valueAnimation: true,
              formatter: function (value) {
                return Math.round(value)
              },
              color: 'auto'
            },
            data: []
          }
        ]

      },
      knowledge:{}
    }
  },
  mounted() {
    this.initKnowledge()
    // this.draw1()
    // this.draw2()
    // this.draw3()
    // this.draw4()
  },
  methods: {
    initKnowledge () {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.KNOWLEDGE.INFO),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.knowledge = data['result'] || {}
          if (this.knowledge && JSON.stringify(this.knowledge) !== '{}') {
            this.draw1()
            this.draw2()
            this.draw3()
            this.draw4()
          }
          else {
            this.defaultDraw()
          }
        }
      })
    },
    defaultDraw() {
      let chartDom = document.getElementById('knowledge')
      let myChart = this.echarts.init(chartDom)
      let option = {
        graphic: {
          elements: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              color: '#f0f0f0',
              style: {
                text: '知识产权信息待完善',
                fontSize: 40,
                fontWeight: 'bold',
                lineDash: [0, 100],
                lineDashOffset: 0,
                fill: 'transparent',
                stroke: '#409eff',
                lineWidth: 1
              },
              keyframeAnimation: {
                duration: 3000,
                loop: true,
                keyframes: [
                  {
                    percent: 0.7,
                    style: {
                      fill: 'transparent',
                      lineDashOffset: 200,
                      lineDash: [200, 0]
                    }
                  },
                  {
                    // Stop for a while.
                    percent: 0.8,
                    style: {
                      fill: 'transparent'
                    }
                  },
                  {
                    percent: 1,
                    style: {
                      fill: 'black'
                    }
                  }
                ]
              }
            }
          ]
        }
      }
      option && myChart.setOption(option)
    },
    draw1 () {
      let k1Dom = document.getElementById('knowledge1')
      let k1Instance = this.echarts.init(k1Dom)
      // this.option1.dataSet.source = [[{value: this.knowledge['tmcount'],name: '商标'}]]
      // console.log()
      this.option1.series[0].data[0] = {value: this.knowledge['tmcount'],name: '商标'}
      k1Instance.setOption(this.option1)
    },
    draw2 () {
      let k2Dom = document.getElementById('knowledge2')
      let k2Instance = this.echarts.init(k2Dom)
      let option2 = Object.assign({}, this.option1)
      option2.series[0].data[0] = {value: this.knowledge['patentCount'],name: '专利'}
      // option2.series[0].data[0].name = '专利'
      k2Instance.setOption(option2)
    },
    draw3 () {
      let k3Dom = document.getElementById('knowledge3')
      let k3Instance = this.echarts.init(k3Dom)
      let option3 = Object.assign({}, this.option1)
      option3.series[0].data[0] = {value: this.knowledge['softCopyCount'],name: '软著'}
      // option3.series[0].data[0].name = '商标'
      k3Instance.setOption(option3)
    },
    draw4 () {
      let k4Dom = document.getElementById('knowledge4')
      let k4Instance = this.echarts.init(k4Dom)
      let option4 = Object.assign({}, this.option1)
      option4.series[0].data[0] = {value: this.knowledge['certificateCount'], name: '资质'}
      k4Instance.setOption(option4)
    }
  }
}
</script>

<style scoped>
  #knowledge {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /*justify-items: flex-start;*/
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10%;
  }
  .knowledge-item {
    width: 45%;
    height: 50%;
  }

</style>

<template>
  <div class="credit">
    <div v-if="resultList && resultList.length > 0" class="credit-item">
      <div v-for="item in resultList" :key="item.key" class="credit-item-sec">
        <div style="margin: 0 auto">
          <dv-water-level-pond :config="config(item)" style="width: 5vw; height: 5vw"></dv-water-level-pond>
        </div>
        <div class="wi100 tac c-white f12 mt-1">{{ params[item.key] }}</div>
      </div>
    </div>
    <div v-else class="wi100 hi100" id="credit"></div>
  </div>
</template>

<script>
import {checkIsNull} from '../../../../../utils'

export default {
  name: 'enterprise-credit',
  props: {
    creditInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      result: {
        // ratingScore: 68,
        // adjustScore: 11,
        // businessDevelopScore: 45,
        // marketActivityScore: 100,
        // ipRScore: 58,
        // caScore: 65.3,
        // prScore: 78,
        // thirdEvaluationScore: 45
      },
      params: {
        creditRatingScore: '定级分值',
        adjustScore: '调整模块分值',
        businessDevelopScore: '业务拓展分值',
        marketActivityScore: '市场活跃分值',
        iprScore: '知识产权分值',
        caScore: '公益活动分值',
        prScore: '助残扶贫分值',
        tirdEvaluationScore: '第三方评价分值'
      },
      colors: [
        {color: '#f56c6c', percentage: 0},
        {color: '#e6a23c', percentage: 5},
        {color: '#6f7ad3', percentage: 10},
        {color: '#1989fa', percentage: 20},
        {color: '#5cb87a', percentage: 30}
      ],
    }
  },
  computed: {
    resultList() {
      let tempList = []
      for (let key in this.creditInfo) {
        if (this.params[key]) {
          tempList.push({key: key, value: this.creditInfo[key]})
        }
      }
      return tempList
    },
    config() {
      return function (data) {
        let colors = ['#00BAFF', '#3DE7C9']
        if (data.value <= 5) {
          colors = ['#e74c3c', '#e74c3c']
        } else if (data.value > 5 && data.value <= 10) {
          colors = ['#00BAFF', '#00BAFF']
        } else if (data.value > 10 && data.value <= 100) {
          colors = ['#5cb87a', '#5cb87a']
        }
        return {
          data: [data.value],
          shape: 'round',
          colors: colors,
          waveNum: 1,
          waveHeight: 15,
          formatter: '{value}'
        }
      }
    }
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      if (JSON.stringify(this.creditInfo) === '{}') {
        this.defaultDraw()
      }
    }, 1000)

  },
  methods: {
    formatter(percentage) {
      return percentage
    },
    defaultDraw() {
      let chartDom = document.getElementById('credit')
      let myChart = this.echarts.init(chartDom)
      let option = {
        graphic: {
          elements: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              color: '#f0f0f0',
              style: {
                text: '企业信用评价(数据待完善)',
                fontSize: 40,
                fontWeight: 'bold',
                lineDash: [0, 100],
                lineDashOffset: 0,
                fill: 'transparent',
                stroke: '#409eff',
                lineWidth: 1
              },
              keyframeAnimation: {
                duration: 3000,
                loop: true,
                keyframes: [
                  {
                    percent: 0.7,
                    style: {
                      fill: 'transparent',
                      lineDashOffset: 200,
                      lineDash: [200, 0]
                    }
                  },
                  {
                    // Stop for a while.
                    percent: 0.8,
                    style: {
                      fill: 'transparent'
                    }
                  },
                  {
                    percent: 1,
                    style: {
                      fill: 'black'
                    }
                  }
                ]
              }
            }
          ]
        }
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.credit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.credit-item {
  /*margin-top: 8%;*/
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
}

.credit-item-sec {
  padding: 20px;
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  /*flex-direction: column;*/
  /*margin: 0 auto;*/
}
</style>

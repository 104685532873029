<template>
  <div class="wi100 hi100">
    <div id="baseInfo" class="base-content wi100 hi100">
      <div class="wi35 hi100 ent-item-content">
        <div class="wi100" style="height: 33%">
          <dv-border-box-12 class="box-item">
            <div class="wi100 hi100 p-2 tac box-item-content">
              <div class="c-white wi100">企业名称</div>
              <div class="ent-info-desc  f14 mt-1">{{enterpriseInfo.name}}</div>
            </div>
          </dv-border-box-12>

        </div>
        <div class="wi100" style="height: 33%">
          <dv-border-box-12 class="box-item" >
            <div class="wi100 hi100 p-2 tac box-item-content">
              <div class="c-white wi100">登记状态</div>
              <div class="ent-info-desc  f14 mt-1">{{enterpriseInfo.status}}</div>
            </div>
          </dv-border-box-12>
        </div>
        <div class="wi100" style="height: 33%">
          <dv-border-box-12 class="box-item">
            <div class="wi100 hi100 p-2 tac box-item-content">
              <div class="c-white wi100">企业类型</div>
              <div class="ent-info-desc f14 mt-1">{{enterpriseInfo.econKind || '--'}}</div>
            </div>
          </dv-border-box-12>
        </div>
      </div>
      <div class="wi30 hi100" style="display: flex;justify-content: center;align-items: center;">
        <div style="width:6vw;height: 6vw;">
          <dv-decoration-12 style="width: 100%;height:100%"></dv-decoration-12>
        </div>
      </div>
      <div class="wi35 hi100 ent-item-content">
        <div class="wi100" style="height: 33%">
          <dv-border-box-12 class="box-item">
            <div class="wi100 hi100 p-2 tac box-item-content">
              <div class="c-white wi100">注册资本</div>
              <div class="ent-info-desc f14 mt-1">{{enterpriseInfo.registCapi || '--'}}</div>
            </div>
          </dv-border-box-12>
        </div>
        <div class="wi100" style="height: 33%">
          <dv-border-box-12 class="box-item">
            <div class="wi100 hi100 p-2 tac box-item-content">
              <div class="c-white wi100">实缴资本</div>
              <div class="ent-info-desc f14 mt-1">{{enterpriseInfo.recCap || '--'}}</div>
            </div>
          </dv-border-box-12>
        </div>
        <div class="wi100" style="height: 33%">
          <dv-border-box-12 class="box-item">
            <div class="wi100 hi100 p-2 tac box-item-content">
              <div class="c-white wi100">人员规模</div>
              <div class="ent-info-desc f14 mt-1">{{enterpriseInfo.personScope || '未说明'}}</div>
            </div>
          </dv-border-box-12>
        </div>
      </div>
<!--      <div class="c-white base-second">-->
<!--        <div>企业名称</div>-->
<!--        <div><span>{{enterpriseInfo.name}}</span></div>-->
<!--&lt;!&ndash;        <div class="tac base-content-item-des">&ndash;&gt;-->
<!--&lt;!&ndash;          <dv-decoration-7>人员规模</dv-decoration-7>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="base-content-item-parent">&ndash;&gt;-->
<!--&lt;!&ndash;          <dv-decoration-9 class="fwb f20 base-content-item">{{enterpriseInfo.personScope}}</dv-decoration-9>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="c-white base-second">-->
<!--        <div class="base-content-item-des tac">-->
<!--          <dv-decoration-7>实缴资本</dv-decoration-7>-->
<!--        </div>-->
<!--        <div class="base-content-item-parent">-->
<!--          <dv-decoration-9 class="fwb f20 base-content-item">{{enterpriseInfo.recCap | capFilter}}</dv-decoration-9>-->
<!--        </div>-->
<!--      </div>-->
    </div>
<!--    <div class="wi100 pt-4">-->
<!--      <dv-scroll-board class="f16" :config="configData" style="width: 100%;height: 1.6rem; position: relative;left: 10px;bottom: 10px"></dv-scroll-board>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'base-info',
  props: {
    enterpriseInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      configData: {
        data: [
          ['是否上市:', '否','成立日期:', '2010-10-23'],
          ['工商注册号：','202201030201203','登记机关：','北京市朝阳区工商局']
        ],
        rowNum: 1,
        align: ['right','left']
      }
    }
  },
  filters: {
    capFilter (val) {
      if (val) {
        return val.replace('元人民币', '')
      }
      return ''

    }
  },
  computed: {
    config () {
      let config = {}
      // let
      return config
    }
  },
  created () {
    // this.initBasicInfo()
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
.base-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 4rem 1rem 1rem 1rem;
  /*margin: 10% auto;*/
  /*margin-top: 5%;*/
}

.base-second {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-items: center;
  width: 50%;
  height: 100%;
}

.base-content-item-parent {
  width: 5vw;
  height: 5vw;
}

.base-content-item {
  width: 100%;
  height: 100%;
  color: #e67e22;
}

.base-content-item-des {
  margin-top: 1rem;
  /*height: 2rem;*/
  width: 180px;
  font-size: 1.4rem;
  font-weight: 800;
  color: #e67e22;
}
.ent-info-desc {
  color: #e67e22;
  min-height: 40%;
}
.ent-item-content {
  display: flex;
  /*flex-direction: column;*/
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
}
.box-item {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.box-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  flex-wrap: wrap;
}

</style>

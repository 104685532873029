<template>
  <div id="valueStrength">
    <div id="radar" class="value-item">
    </div>
    <div class="value-item pl-5 p-2 c-white f20">
      <dv-capsule-chart :config="chartConfig" class="wi100 hi100"></dv-capsule-chart>
    </div>
  </div>
</template>

<script>
const echarts = require('echarts')
export default {
  name: 'value-strength',
  data() {
    return {
      option: {
        radar: {
          indicator: [
            {text: '资本力', max: 100},
            {text: '市场力', max: 100},
            {text: '创新力', max: 100},
            {text: '责任力', max: 100}
          ],
          axisName: {
            color: '#e67e22',
            fontWeight: 'bold'
          },
        },
        series: [
          {
            name: '企业价值力',
            type: 'radar',
            tooltip: {
              trigger: 'item'
            },
            itemStyle: {
              color: '#e67e22'
            },
            data: [
              {
                areaStyle: {
                  color: '#e67e22',
                },
                lineStyle: {
                  color: '#e67e22',
                  cap: 'round'
                },
                value: [70, 60, 50, 90]
              }
            ]
          }
        ]
      },
      result: [100, 70, 60, 80],
      response: {
        capitalPower: 100,
        marketPower: 70,
        innovationPower: 60,
        responsibilityPower: 80
      }
    }
  },
  computed: {
    chartConfig() {
      let response = {...this.response}
      let data = []
      let names = {
        capitalPower: '资本力',
        marketPower: '市场力',
        innovationPower: '创新力',
        responsibilityPower: '责任力'
      }
      for (let key in response) {
        let value = typeof response[key] === 'number' ? response[key].toFixed(2) : response[key]
        let item = {name: names[key], value}
        data.push(item)
      }
      let config = {
        data,
        showValue: true,
        colors: ['#37a2da', '#e062ae', '#67e0e3', '#9fe6b8', '#ffdb5c', '#ff9f7f', '#fb7293']
      }
      return config
    }
  },
  mounted() {
    this.getNearest()
  },
  methods: {
    draw() {
      let dom = document.getElementById('radar')
      let chartInstance = echarts.init(dom)
      chartInstance.setOption(this.option)
    },
    getNearest() {
      this.$http({
        url: this.$http.adornUrl(this.$api.TECH.BRAND.NEAREST),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.data) {
            this.result = []
            let capitalPower = data.data['capitalPower'] || 100
            this.result.push(capitalPower)
            this.response['capitalPower'] = capitalPower

            let marketPower = data.data['marketPower'] || 70
            this.result.push(marketPower)
            this.response['marketPower'] = marketPower

            let innovationPower = data.data['innovationPower'] || 60
            this.result.push(innovationPower)
            this.response['innovationPower'] = innovationPower

            let responsibilityPower = data.data['responsibilityPower'] || 80
            this.result.push(responsibilityPower)
            this.response['responsibilityPower'] = responsibilityPower
          }
          this.option.series[0].data[0].value = this.result
          this.draw()
        }
      })
    },
  }
}
</script>

<style scoped>
#valueStrength {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.value-item {
  height: 100%;
  width: 50%;
}
</style>

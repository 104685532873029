<template>
  <div id="risk" class="wi100 hi100">
  </div>
</template>

<script>
const echarts = require('echarts')
export default {
  name: 'enterprise-risk',
  props: {
    enterpriseId: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      colors: ['#f56c6c', '#e6a23c', '#5cb87a', '#1989fa', '#6f7ad3', '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
      source: [
        {
          value: 13, name: '失信执行条目数'
        },
        {
          value: 10, name: '被执行人条目数'
        },
        {
          value: 8, name: '裁判文书条目数'
        },
        {
          value: 2, name: '司法拍卖条目数'
        },
        {
          value: 9, name: '经营异常条目数'
        },
        {
          value: 3, name: '开庭公告条目数'
        },
        {
          value: 7, name: '税收违法条目数'
        },
        {
          value: 11, name: '股权出质条目数'
        },
        {
          value: 5, name: '动产抵押条目数'
        },
        {
          value: 0, name: '股权冻结条目数'
        }
      ],
      keyMap: {
        sxCount: '失信执行条目数',
        zxCount: '被执行人条目数',
        judCount: '裁判文书条目数',
        jsCount: '司法拍卖条目数',
        exCount: '经营异常条目数',
        csaCount: '开庭公告条目数',
        tiCount: '税收违法条目数',
        pleCount: '股权出质条目数',
        mleCount: '动产抵押条目数',
        asCount: '股权冻结条目数',
        ctaCount: '法院公告条目数',
        lmCount: '土地抵押条目数',
        epCount: '环保处罚条目数',
        svCount: '严重违法条目数',
        laCount: '立案数量',
        dlnCount: '送达公告条目数',
        toCount: '欠税公告条目数',
        pnCount: '公示催告条目数',
        scCount: '简易注销条目数',
        dwCount: '对外担保条目数',
        wgCount: '违规处理条目数',
        stpCount: '股权质押条目数',
        stCount: '限制消费条目数',
        encCount: '终本案件条目数',
        bryCount: '破产重整条目数',
        ineCount: '询价评估-评估结果条目数',
        elqCount: '注销备案条目数',
        apCount: '行政处罚条目数',
        loCount: '限制出境条目数'
      },
      barSource: [],
      timer: {},
      intervalHandler: {},
      riskInfo: {}
    }
  },
  computed: {
    pondConfig() {
      return {
        value: 66
      }
    }
  },
  mounted() {
    this.initRisk()
  },
  destroyed() {
    clearTimeout(this.timer)
    clearInterval(this.intervalHandler)
  },
  methods: {
    initRisk () {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.RISK.INFO),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.riskInfo = data['result'] || {}
          let dataList = []
          for(let key in this.riskInfo) {
            if(this.keyMap[key] && this.riskInfo[key] > 0) {
              dataList.push({value: this.riskInfo[key], name: this.keyMap[key]})
            }
          }
          // 排序
          let sortedList = dataList.sort(function (a,b) {
            return b.value -a.value
          })
          this.source = sortedList
          if (this.source && this.source.length > 0) {
            this.draw()
          } else {
            this.defaultDraw()
          }
        }
      })
    },
    draw () {
      let dom = document.getElementById('risk')
      let chartInstance = this.echarts.init(dom)

      let source = [...this.source]
      // 排序
      source.sort(function (a, b) {
        return b.value - a.value
      })
      let pieOption = {
        legend: {
          show: true,
          top: 'right'
        },
        title: {
          text: '企业自身风险',
          textStyle: {
            color: '#d35400',
            fontSize: 18,
            fontWeight: 'bold'
          },
          left: 'center'
        },
        tooltip: {
          show: true,
          trigger: 'item'
        },
        dataset: {
          source: []
        },
        series: [
          {
            name: '企业风险',
            animationDuration: 2000,
            animationEasing: 2000,
            universalTransition: true,
            type: 'pie',
            id: 'risk',
            // encode: {
            //   tooltip: ['name','value']
            // },
            label: {
              color: '#FFFFFF',
              fontWeight: 'bold',
              fontSize: 16,
              backgroundColor: 'transparent'
            },
            // radius: [50,250],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
          }
        ]
      }
      pieOption.dataset.source = source
      let curOption = pieOption
      chartInstance.setOption(curOption)

      let barData = source.map(function (item) {
        return item.value
      })
      let colors = this.colors
      let richBarData = barData.map(function (value, index) {
        return {
          value,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
              offset: 0,
              color: colors[index], // 0% 处的颜色
            },
              {
                offset: 0.6,
                color: colors[index], // 60% 处的颜色
              },
              {
                offset: 1,
                color: colors[index], // 100% 处的颜色
              }], false)
          }
        }
      })
      let barOption = {
        title: {
          text: '企业自身风险',
          textStyle: {
            color: '#d35400',
            fontSize: 18,
            fontWeight: 'bold'
          },
          left: 'center'
        },
        xAxis: {
          type: 'category',
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: '#FFFFFF'
            }
          },
          axisLabel: {
            show: true,
            interval: 0,
            inside: false,
            rotate: 30
          },
          data: source.map(function (item) {
            return item.name
          })
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            // inside: true
          }
        },
        color: ['#1989f'],
        animationDuration: 2000,
        animationEasing: 2000,
        series: [
          {
            type: 'bar',
            id: 'risk',
            label: {
              show: true,
              position: 'insideBottom',
              align: 'center',
              verticalAlign: 'middle',
              rotate: 0,
              formatter: '{c}'
            },
            universalTransition: true,
            data: richBarData
          }
        ]
      }
      this.intervalHandler = setInterval(function () {
        curOption = curOption === pieOption ? barOption : pieOption
        chartInstance.setOption(curOption, true)
      }, 6000)
    },
    defaultDraw () {
      var chartDom = document.getElementById('risk')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        graphic: {
          elements: [
            {
              type: 'text',
              left: 'center',
              top: 'center',
              color: '#f0f0f0',
              style: {
                text: '企业风险情况(数据待完善)',
                fontSize: 40,
                fontWeight: 'bold',
                lineDash: [0, 100],
                lineDashOffset: 0,
                fill: 'transparent',
                stroke: '#409eff',
                lineWidth: 1
              },
              keyframeAnimation: {
                duration: 3000,
                loop: true,
                keyframes: [
                  {
                    percent: 0.7,
                    style: {
                      fill: 'transparent',
                      lineDashOffset: 200,
                      lineDash: [200, 0]
                    }
                  },
                  {
                    // Stop for a while.
                    percent: 0.8,
                    style: {
                      fill: 'transparent'
                    }
                  },
                  {
                    percent: 1,
                    style: {
                      fill: 'black'
                    }
                  }
                ]
              }
            }
          ]
        }
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
#risk {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.risk-item {
  width: 20%;
  height: 40%;
  display: flex;
  justify-content: center;
  justify-items: flex-end;
  flex-direction: column;
  text-align: center;
}

.risk-item-des {
  color: #c0392b;
  font-weight: bold;
}
</style>

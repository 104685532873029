import { render, staticRenderFns } from "./enterprise-risk.vue?vue&type=template&id=5c08a821&scoped=true&"
import script from "./enterprise-risk.vue?vue&type=script&lang=js&"
export * from "./enterprise-risk.vue?vue&type=script&lang=js&"
import style0 from "./enterprise-risk.vue?vue&type=style&index=0&id=5c08a821&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c08a821",
  null
  
)

export default component.exports
<template>
  <dv-full-screen-container class="bg">
    <dv-loading v-if="loading"><span style="color:#FFFFFF">正在加载...</span></dv-loading>
    <template v-else>
      <div class="left">
        <div class="item-container">
          <dv-border-box-11 title="工商信息" class="p-2">
            <div class="wi100 hi100">
              <base-info :enterpriseInfo="enterpriseInfo"/>
            </div>
          </dv-border-box-11>
        </div>
        <div class="item-container">
          <dv-border-box-12 class="p-3">
            <!--            <div class="fwb f18 wi100 tac" style="color:#27ae60">企业经营状况</div>-->
            <performance :enterpriseId="enterpriseId"/>
          </dv-border-box-12>
        </div>
        <div class="item-container2">
          <dv-border-box-13 class="pt-5 pl-3 pr-3">
            <knowledge :enterpriseId="enterpriseId"/>
          </dv-border-box-13>
        </div>
      </div>
      <div class="bg-center p-2">
        <div class="top-content">
          <dv-border-box-1 class="tac">
            <div class="hi100">
              <dv-decoration-11 class="fwb f24" style="width: 80%;margin-left:10%;height: 10%;color: #FFFFFF">
                <div @click="$router.go(-1)" class="ent-title">服务型制造共性技术公共服务平台</div>
              </dv-decoration-11>
              <div class="wi100 p-3 hi100">
                <enterprise-total :enterpriseId="enterpriseId" :tagList="tagList" :level="creditEntity.creditLevel"
                                  :score="creditEntity.creditScore"/>
              </div>
            </div>
          </dv-border-box-1>
        </div>
        <div class="bg-bottom pt-2">
          <dv-border-box-12 class="p-2">
            <div class="wi100 hi100 p-2">
              <div class="p-2" style="width: 100%;height: 40%;">
                <news-line :impactList="impactList"></news-line>
              </div>
              <div class="wi100" style="height: 60%">
                <news-list :newsList="newsList"/>
              </div>
            </div>
          </dv-border-box-12>
        </div>
      </div>
      <div class="right">
        <div class="item-container">
          <dv-border-box-11 class="p-3" title="企业价值力">
            <div class="second-content">
              <value-strength/>
            </div>
          </dv-border-box-11>
        </div>
        <div class="item-container">
          <dv-border-box-12 class="p-3">
            <!--          <div class="tac fwb f18 c-white">企业自身风险</div>-->
            <enterprise-risk :enterpriseId="enterpriseId"/>
          </dv-border-box-12>
        </div>
        <div class="item-container2">
          <dv-border-box-13 class="pt-5">
            <div class="wi100 hi100">
              <div v-if="creditEntity && JSON.stringify(creditEntity) !== '{}'" class="wi100 tac c-white fwb f18 mb-4"
                   style="color: #409eff">企业信用评价
              </div>
              <enterprise-credit :creditInfo="creditEntity"/>
            </div>
          </dv-border-box-13>
        </div>
      </div>
    </template>
  </dv-full-screen-container>
</template>

<script>
import EnterpriseTotal from './components/enterprise-total'
import NewsLine from './components/news-line'
import BaseInfo from './components/base-info'
import Performance from './components/performance'
import Knowledge from './components/knowledge'
import ValueStrength from './components/value-strength'
import EnterpriseRisk from './components/enterprise-risk'
import NewsList from './components/news-list'
import EnterpriseCredit from './components/enterprise-credit'
import {checkIsNull} from '../../../../utils'

export default {
  name: 'big-screen',
  components: {
    NewsLine,
    BaseInfo,
    Performance,
    Knowledge,
    ValueStrength,
    EnterpriseRisk,
    NewsList,
    EnterpriseCredit,
    EnterpriseTotal
  },
  data() {
    return {
      creditEntity: {},
      // 舆情新闻
      newsList: [],
      // 舆情情感统计
      newsImpactList: [],
      impactList: [],
      enterpriseInfo: {},
      // 企业标签
      tagList: [],
      loading: false
    }
  },
  computed: {
    enterpriseList: {
      get() {
        return this.$store.state.user.info
      },
      // set (val) { this.$store.commit('user/updateInfo', val) }
    },
    enterpriseId() {
      return this.enterpriseList[0] ? this.enterpriseList[0].id : ''
    }
  },
  created() {
    this.initBasicInfo()
    this.initCredit()
    this.initNews()
  },
  mounted() {
  },
  methods: {
    // 基本信息
    initBasicInfo() {
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.ENT_INFO.INFO),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.enterpriseInfo = data['result'] || {}
          this.tagList = this.enterpriseInfo['qccEntTagList'] || []
        }
      })
    },
    initCredit() {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.CREDIT.INFO),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: this.enterpriseId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let result = data['result']
          if (!checkIsNull(result)) {
            let creditRateEntity = result['qccCreditRatingEntity'] || {}
            let qccCreditAdjustEntity = result['qccCreditAdjustEntity'] || {}
            let qccCreditAdjustBdEntity = qccCreditAdjustEntity['qccCreditAdjustBdEntity'] || []
            let qccCreditAdjustCaEntity = qccCreditAdjustEntity['qccCreditAdjustCaEntity'] || []
            let qccCreditAdjustIprEntity = qccCreditAdjustEntity['qccCreditAdjustIprEntity'] || []
            let qccCreditAdjustMaEntity = qccCreditAdjustEntity['qccCreditAdjustMaEntity'] || []
            let qccCreditAdjustPrEntity = qccCreditAdjustEntity['qccCreditAdjustPrEntity'] || []
            let qccCreditAdjustTeEntity = qccCreditAdjustEntity['qccCreditAdjustTeEntity'] || []
            this.creditEntity = {
              creditLevel: result.creditLevel,
              creditScore: result.creditScore, ...creditRateEntity, ...qccCreditAdjustEntity, ...qccCreditAdjustBdEntity,
              ...qccCreditAdjustCaEntity, ...qccCreditAdjustIprEntity, ...qccCreditAdjustMaEntity,
              ...qccCreditAdjustPrEntity, ...qccCreditAdjustTeEntity
            }
          }
        }
      })
    },
    initNews() {
      // this.loading = true
      // this.$http({
      //   url: this.$http.adornUrl(this.$api.QCC.NEWS.LIST),
      //   method: 'GET',
      //   params: this.$http.adornParams({
      //     enterpriseId: this.enterpriseId
      //   })
      // }).then(({data}) => {
      //   if (data && data.code === 0) {
      //     this.newsList = [...data['result']['news']]
      //     this.impactList = data['result']['impact'] || []
      //   }
      //   this.loading = false
      // })
    },
  }
}
</script>

<style scoped>
.bg {
  display: flex;
  width: 100%;
  justify-content: space-between;
  justify-items: center;
  background-color: black;
  /*background-image: url("../../../../assets/img/tech-analysis/pageBg1.png");*/
  background-size: cover;
  background-position: center center;
}

.ent-title {
  cursor: pointer;
}

.left {
  width: 30%;
  height: 100%;
}

.bg-center {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-center .top-content {
  width: 100%;
  height: 45%;
}

.bg-center .bg-bottom {
  width: 100%;
  height: 55%;
}

.right {
  width: 30%;
  height: 100%;
}

.item-container {
  width: 100%;
  height: 30%;
}

.item-container2 {
  width: 100%;
  height: 40%;
}

.second-content {
  padding: 8% 4% 6% 4%;
  width: 100%;
  height: 100%;
}

</style>

<template>
  <div id="newsLine">
  </div>
</template>

<script>
const echarts = require('echarts')
import moment from 'moment'

export default {
  name: 'news-line',
  props: {
    impactList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      option: {
        color: ['rgb(255, 191, 0)', 'rgb(0, 221, 255)', 'rgb(116, 21, 219)'],
        title: {
          text: '新闻舆论走向'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['积极舆论', '中立舆论', '消极舆论']
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        dataset: {
          source: []
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            gridIndex: 0
            // data: [{type: 'category',gridIndex: 0}]
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '积极舆论',
            seriesLayoutBy: 'row',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(255, 191, 0)'
                },
                {
                  offset: 1,
                  color: 'rgb(224, 62, 76)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            // data: []
          },
          {
            name: '中立舆论',
            seriesLayoutBy: 'row',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(0, 221, 255)'
                },
                {
                  offset: 1,
                  color: 'rgb(77, 119, 255)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            // data: []
          },
          {
            name: '消极舆论',
            seriesLayoutBy: 'row',
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(55, 162, 255)'
                },
                {
                  offset: 1,
                  color: 'rgb(116, 21, 219)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            // data: []
          }
        ]
      }
    }
  },
  mounted() {
    this.process(this.impactList)
  },
  watch: {
    impactList: {
      handler: function (list) {
        if (list && list.length > 0) {
          this.process(list)
        }
      },
      deep: true
    }
  },
  methods: {
    process(dataList) {
      // 处理datasource
      let impactList = [...dataList]
      let xAxisData = []
      let list = []
      let negList = []
      let posList = []
      let noneList = []
      negList.push('消极舆论数量')
      posList.push('积极舆论数量')
      noneList.push('中立舆论数量')
      xAxisData.push('time')

      impactList.forEach(item => {
        negList.push(item['negativeCount'])
        posList.push(item['positiveCount'])
        noneList.push(item['noneCount'])
        let createTime = item['createTime']
        createTime = createTime ? moment(createTime).format('yyyy-MM-DD') : createTime
        xAxisData.push(createTime)
      })

      list.push(xAxisData)
      list.push(posList)
      list.push(noneList)
      list.push(negList)

      this.option.dataset.source = list
      this.drawLine()
    },
    drawLine() {
      let chartDom = document.getElementById('newsLine')
      let myChart = echarts.init(chartDom)
      myChart.setOption(this.option)
    }
  }
}
</script>

<style scoped>
#newsLine {
  width: 100%;
  height: 100%;
}
</style>
